// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconAchievers({ className, size, color = "#97a3ae" }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 30 30" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path id="Achievers_Plus_logo" d="M22.612,21.31c-.29-.915-.535-1.942-.758-2.81h-.045c-.223.872-.445,1.92-.715,2.813l-1.167,3.705H23.8Zm0,0c-.29-.915-.535-1.942-.758-2.81h-.045c-.223.872-.445,1.92-.715,2.813l-1.167,3.705H23.8ZM25,10A15,15,0,1,0,40,25,15,15,0,0,0,25,10Zm9.643,17.1H30.75v4.252H28.583V27.1h-1.4l1.382,4.252H25.625l-1.36-4.265H19.487l-1.3,4.265H15.358l4.82-15.043h3.5l2.833,8.708h2.08V20.955h2.167V25.02h3.893ZM24.7,25.02V27.1h2.487l-.677-2.083ZM21.853,18.5h-.045c-.223.872-.445,1.92-.715,2.813l-1.167,3.705H23.8l-1.183-3.7C22.322,20.4,22.077,19.368,21.853,18.5Z" transform="translate(-10 -10)"/>
    </svg>
  );
}

IconAchievers.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
