import PropTypes from "prop-types";
import styles from "./EventTicketItem.module.css";
import TicketItem from "components/TicketItem/TicketItem";

/**
 * Item shown in list when viewing tickets
 */
export default function EventTicketItem({ ibo, ticket, onView = () => {} }) {
  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <div>
          <img src={ticket.icon} alt="Ticket Icon" />
        </div>
        <div>{ticket.title}</div>
        <div>{ticket.location}</div>
        <div>{ticket.date}</div>
      </div>
      <TicketItem ticketInfo={ticket} onClick={onView} ibo={ibo} />
    </div>
  );
}

EventTicketItem.propTypes = {
  ibo: PropTypes.object.isRequired,
  ticket: PropTypes.object.isRequired,
  onView: PropTypes.func, // Optional function prop type
};
