import styles from "./PinIcon.module.css";
import NoteIcon from "./Note_Icon.svg";

/**
 * View Component. Displays a checkmark pin icon for agenda items
 */

export function PinIcon({ onClick }) {
  return (
    <img
      src={NoteIcon}
      className={styles.img}
      alt="Voting Result is Complete"
      onClick={(e) => onClick && onClick(e)}
    />
  );
}

PinIcon.propTypes = {};
