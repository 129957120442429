import { CircleLoader } from "@wwg/ui";
import Contact from "components/Contact/Contact";
import { IconClose } from "components/Icons/IconClose";
import QRCode from "components/QRCode/QRCode";
import env from "environment";
import { useEffect, useState } from "react";
import { Fragment } from "react";
import { useQueryClient } from "react-query";
import { useParams, useRouteMatch } from "react-router-dom";
import mobile from "shared/mobileBridge";
import TicketContext from "stores/TicketContext";
import { useContainer } from "unstated-next";
import { makeAuthedRequest } from "utils/makeAuthedRequest";
import { useTicketData } from "../Event/useTicketData";
import Event from "./../Event/Event";
import styles from "./IdProfile.module.css";
import { Profile, useProfileData } from "./useProfileData";
import UserProfile from "components/UserProfile/UserProfile";
import TicketList from "components/TicketList/TicketList";

import exampleQR from "../../../assets/icons/QR_Code_Icon.svg";
import exampleIcon from "../../../assets/icons/Spring_Leadership_Icon_Ticket.svg";
import exampleIcon2 from "../../../assets/icons/Seminar_Icon.svg";
import PinIcon from "components/PinIcon";

import slIcon from "../../../assets/icons/Spring_Leadership_Icon.svg";
import exampleHeaderIcon from "../../../assets/icons/Regional_Rally_Icon_Group.svg";
import EventOrdersButton from "components/EventOrdersButton/EventOrdersButton";
import { useHistory } from "react-router-dom";

export default function () {
  const { params } = useRouteMatch();
  const { id } = params;
  // console.log("parans contactId", id)
  return (
    <Profile.Provider initialState={id}>
      <IdProfile />
    </Profile.Provider>
  );
}

export function IdProfile() {
  const queryClient = useQueryClient();
  const {
    profile,
    isError,
    isLoading,
    error,
    setContactId,
    contactId,
    saveAvatar,
  } = Profile.useContainer();

  const { tickets } = useTicketData({ contactId: contactId });

  const [checkedInTicket, setCheckedInTicket] = useState(null);

  useEffect(() => {
    if (profile.contactId > 0) {
      if (contactId != profile.contactId) {
        setContactId(profile.contactId);
      }
    }
  }, [profile.contactId]);

  const selectedContact =
    profile &&
    profile.contactOptions &&
    profile.contactOptions.length > 0 &&
    profile.contactOptions.find((a) => a.WWGNumber == contactId);

  const setSelectedContact = function (contact) {
    // console.log("selectedContact", contact)
    setContactId(contact.WWGNumber);
    onCloseClicked();
  };

  const onCloseClicked = function () {
    // console.log("onCloseClicked")
    setCheckedInTicket(null);
  };

  const onCheckIn = function (ticket) {
    // console.log("onCheckIn", ticket)

    if (ticket.qrCode == null) {
      makeAuthedRequest(`/api/ticket/checkin`, {
        method: "post" /*headers: { "wwg-contact-id": selectedContactId}, */,
        body: JSON.stringify({ ...ticket, contactId: contactId }),
      }).then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          ticket.qrCode = json.qrCode;
          ticket.contactId = json.contactId;
          setCheckedInTicket(ticket);
          queryClient.invalidateQueries("tickets");
          return json;
        } else {
          throw new Error(json.message);
        }
      });
    } else {
      setCheckedInTicket(ticket);
    }
  };

  function updateAvatar(image, avatarOriginal) {
    // avatar = image;
    // contact.AvatarUrl = image;

    // makeAuthedRequest(
    //   `/api/profile/avatar`,
    //   {
    //     method: "PUT",
    //     body: JSON.stringify({
    //       WWGNumber: contactId,
    //       AvatarOriginal: avatarOriginal,
    //       Avatar: image,
    //     })
    // })
    saveAvatar
      .mutateAsync({
        WWGNumber: contactId,
        AvatarOriginal: avatarOriginal,
        Avatar: image,
      })
      .then((a) => {
        //update avatar on mobile
        if (mobile.isApp()) {
          mobile.postMessage(
            "update-contact",
            {
              WWGNumber: contactId,
              AvatarOriginal: "", //avatarOriginal,//probably not needed on mobile
              Avatar: image,
            },
            false
          );
        }
      });
  }

  function onPurchase() {
    // console.log("onPurchase()");
    if (mobile.isApp()) {
      mobile.openLink(env.purchaseUrl);
    } else {
      window.parent.postMessage(
        {
          action: "navigate-to-app",
          params: {
            appName: "events-sales",
            path: "/",
          },
        },
        "*"
      );
    }
  }
  const history = useHistory();

  function onReceipt() {
    console.log("Navigating to receipts...");

    if (mobile.isApp()) {
      console.log("Using mobile.openLink with URL:", env.receiptUrl);
      mobile.openLink(env.receiptUrl);
    } else {
      console.log("Navigating using react-router history");
      history.push("/receipts");
    }
  }

  return (
    <div className={styles["container"]}>
      <div className={styles["id-card"]}>
        {isError ? (
          <ul className={styles["errors"]}>
            <li key={"error"}>{"Error"}</li>
          </ul>
        ) : !selectedContact ? (
          <Fragment>
            <CircleLoader loading={!selectedContact} />
          </Fragment>
        ) : (
          <div className={styles.profileContainer}>
            <UserProfile
              ibos={[
                {
                  Name: "Susie Anderson",
                  iboNum: "234567891",
                  WWGNumber: "986453125",
                  AvatarUrl: "https://i.pravatar.cc/300",
                  status: "Platinum",
                  eagle: "Double",
                },
                {
                  Name: "Fenton-Ashton Anderson",
                  iboNum: "234567891",
                  WWGNumber: "986453124",
                  AvatarUrl: "https://i.pravatar.cc/300",
                  status: "Platinum",
                  eagle: "Double",
                },
              ]}
              size={"Small"}
              onChangeImg={() => alert("Changed")}
              onViewOrders={onReceipt}
              onProfileChange={() => alert("Profile Change")}
            />
            <div className={styles.ticketsHeader}>Active Tickets</div>
            <div className={styles.ticketListContainer}>
              <TicketList
                notifications={[]}
                location={""}
                speaker={""}
                tickets={[
                  {
                    ticket: {
                      qrCode: exampleQR,
                      ticketId: 1,
                      title: "",
                      icon: exampleIcon,
                      date: "April 15-16",
                      location: "Las Vegas, NV",
                    },
                    ibo: {
                      name: "Fenton-Ashton",
                      img: "https://wwgus.blob.core.windows.net/wwg-id-card/default-original",
                    },
                  },
                ]}
                headerInfo={{
                  title: "Regional Rally Meeting™",
                  icon: slIcon,
                  bgColor: "#243746",
                }}
                date={"April 15–16, 2023"}
              />
            </div>

            <div className={styles.ticketListContainer}>
              <TicketList
                notifications={[
                  {
                    img: <PinIcon />,
                    notification:
                      "The parking lot is full. You will need to park across the street in the Church. You will possibly be charged $10.00 for parking.",
                  },
                ]}
                location={"Ontario, CA "}
                speaker={"Kevin & Andrea Phillips"}
                tickets={[
                  {
                    ticket: {
                      qrCode: exampleQR,
                      ticketId: 1,
                      title: "",
                      icon: exampleIcon,
                      date: "April 15-16",
                      location: "Las Vegas, NV",
                    },
                    ibo: {
                      name: "Fenton-Ashton",
                      img: "https://wwgus.blob.core.windows.net/wwg-id-card/default-original",
                    },
                  },
                  {
                    ticket: {
                      qrCode: exampleQR,
                      ticketId: 1,
                      title: "Seminar",
                      icon: exampleIcon2,
                      date: "",
                      location: "",
                    },
                    ibo: {
                      name: "Fenton-Ashton",
                      img: "https://wwgus.blob.core.windows.net/wwg-id-card/default-original",
                    },
                  },
                ]}
                headerInfo={{
                  title: "Regional Rally Meeting™",
                  color: "",
                  icon: exampleHeaderIcon,
                }}
                date={"Wednesday November 9"}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
