import PropTypes from "prop-types";
import styles from "./TicketListHeader.module.css";
import classNames from "classnames";
import { ChevronDown, ChevronRight } from "react-ikonate";

/**
 * Header used when displaying lists of purchased tickets. If it’s a major function (Spring Leadership, Family Reunion, or Free Enterprise), the header will also display the event location.
 */
export default function TicketListHeader({
  img,
  title,
  onClick,
  bgColor,
  location,
  opened
}) {
  return (
    <div className={classNames(styles.container)} style={{backgroundColor: bgColor}} onClick={() => onClick()}>
      {" "}
      <div>
        <div className={styles.iconContainer}><img  className={styles.icon} src={img} alt={"Icon"} /></div>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.chevron}>
        {opened != null && (opened ? <ChevronDown width={30} height={30} color={"white"}></ChevronDown> : <ChevronRight width={30} height={30} color={"white"}></ChevronRight>)}
      </div>
    </div>
  );
}

TicketListHeader.propTypes = {
  /**
   * Image to display above title
   */
  img: PropTypes.string,
  /**
   * Title of the events list.
   */
  title: PropTypes.string,
  /**
   * Function to open the list
   */
  onClick: PropTypes.string,
  /**
   * Background color of the header. The color for this header is determined by the event type.
   */
  bgColo: PropTypes.string,
  /**
   * Optional location if the event is a major function
   */
  location: PropTypes.string,
};
