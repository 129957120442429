import styles from "./ReceiptItem.module.css";
import PropTypes from "prop-types";

/**
 * Shows info about a receipt in the receipts list
 */

export function ReceiptItem({ info, onClick }) {
  return (
    <div className={styles.container} onClick={() => onClick()}>
      <div className={styles.icon}>
        <img src={info.icon} alt={"Ticket Icon"} />
      </div>
      <div className={styles.centerContainer}>
        <div className={styles.name}>{info.name}</div>
        <div className={styles.info}>{info.ticketNum}</div>
        <div className={styles.info}>{info.purchaseDate}</div>
        <div className={styles.time}>{info.purchaseTime}</div>
      </div>
      <div className={styles.total}>
        <div>${info.total}</div>
      </div>
    </div>
  );
}

ReceiptItem.propTypes = {
  /**
   * Objects with the receipts info (icon-String, name-String, ticketNum-String, purchaseDate-String, purchaseTime-String, total-String)
   */
  info: PropTypes.element,
  /**
   * Function called when the item is clicked (Opens the receipt page)
   */
  onClick: PropTypes.func,
};
