import { useRef, useCallback, useState } from "react";

import { createContainer } from "unstated-next";
import mediaTyper from "media-typer";
import contentType from "content-type";
import { makeAuthedRequest } from "utils/makeAuthedRequest";
/**
 * Caches responses by URL and returns the same response if the URL is called again.
 *
 * @example
 * // Add the provider somewhere up the component tree.
 * // All components under a provider will share a cache.
 * <RequestCache.Provider>
 *   // ... your components ... //
 * </RequestCache.Provider>
 *
 * // Get a container reference in your component.
 * const cache = RequestCache.useContainer();
 *
 * // Pass cache as 3rd param to useAuthedRequest or makeAuthedRequest.
 * const { data, loading, error } = useAuthedRequest(url, options, cache);
 *
 * // Clear a specific URL
 * cache.clear("/membership/los/frontline/5365412");
 *
 * // Clear everything
 * cache.clearAll();
 */
export function useRequestCache() {
  // Store cache as a ref so we can mutate without re-renders.
  const cache = useRef({});

  /**
   * Makes a cached request. Wraps makeAuthedRequest.
   *
   * @param {string} url - The path or URL to request.
   * @param {Object} fetchOptions - An options object forwarded to `fetch`
   */
  const makeCachedRequest = useCallback(
    async (url, fetchOptions) => {
      if (cache.current[url]) {
        return cache.current[url];
      }

      const res = await makeAuthedRequest(url, fetchOptions);
      const parsed = await parseResponse(res);

      if (res.ok) {
        cache.current[url] = parsed;

        return parsed;
      } else {
        throw new Error(parsed.message);
      }
    },
    [cache]
  );

  /**
   * Removes a path from the cache so the next request will get a fresh response.
   */
  const clear = useCallback(
    (path) => {
      cache.current[path] = null;
    },
    [cache]
  );

  /**
   * Empties the entire cache so all requests start fresh.
   */
  const clearAll = useCallback(() => {
    cache.current = {};
  }, [cache]);

  return {
    __cache: cache,
    makeCachedRequest,
    clear,
    clearAll,
  };
}

export const RequestCache = createContainer(useRequestCache);

async function parseResponse(res) {
  const contentTypeHeader = res.headers.get("content-type");

  let data = null;

  if (isJSON(contentTypeHeader)) {
    data = await res.json();
  } else {
    data = await res.text();
  }

  return data;
}

function isJSON(contentTypeHeader) {
  if (contentTypeHeader) {
    const ct = contentType.parse(contentTypeHeader);
    const mediaType = mediaTyper.parse(ct.type);

    if (mediaType.subtype === "json") {
      return true;
    }

    if (mediaType.suffix === "json") {
      return true;
    }

    if (mediaType.suffix && /\bjson\b/i.test(mediaType.suffix)) {
      return true;
    }

    if (mediaType.subtype && /\bjson\b/i.test(mediaType.subtype)) {
      return true;
    }
  }
  return false;
}
