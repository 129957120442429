import styles from "./App.module.css";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { IconDiamond } from "@wwg/icons";
import { AppFrame, AppHeader, FlowDown, FlowLayout } from "@wwg/ui";
import IdCard from "pages/Core/IdCard/IdCard";
import { useEffect } from "react";

import env from "environment";
import { makeAuthedRequest } from "utils/makeAuthedRequest";
import { QueryClient, QueryClientProvider } from "react-query";
import mobile from "shared/mobileBridge";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RequestCache } from "shared/hooks/useRequestCache";
import ReceiptsSummary from "pages/Core/ReceiptsSummary/ReceiptsSummary";
import TicketDetails from "pages/Core/TicketDetails/TicketDetails";
import IdProfile from "pages/Core/IdProfile/IdProfile";

// console.log("env", env);
const queryClient = new QueryClient();
/**
 * This is the main structure component where the top-level navigation is defined.
 */
export function App() {
  // redirect if verification needed
  useEffect(() => {
    var url = env.coreApiUrl + "/v1/verification/redirect";

    makeAuthedRequest(url, {
      method: "post",
      body: JSON.stringify({ currentAppUrl: window.location.href }),
      credentials: "include",
    })
      .then(async (res) => {
        const json = await res.json();

        if (res.ok && json.shouldRedirect && json.redirectUrl) {
          document.cookie =
            "returnUrl=" +
            window.location.href +
            ";path=/;domain=" +
            env.rootDomain;

          window.location.assign(json.redirectUrl);
        }
      })
      .catch();
  }, []);

  return (
    <RequestCache.Provider>
      <QueryClientProvider client={queryClient}>
        {/* <AppFrame
              mobileBridge={mobile}
              header={{
                title: ""
              }}
              navigation={[]}
              // maxHeaderWidth={1100}
              // maxContentWidth={1100}
              // maxFooterWidth={1100}
            > */}
        {/* <FlowLayout debug={false}>
                <FlowDown> */}
        <Switch>
          <Route path="/ticket/details">
            <TicketDetails />
          </Route>
          <Route path="/receipts">
            <ReceiptsSummary />
          </Route>
          <Route path="/:id?">
            <IdProfile />
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
        {/* </FlowDown>
              </FlowLayout> */}
        {/* </AppFrame> */}
      </QueryClientProvider>
      <ToastContainer />
    </RequestCache.Provider>
  );
}
