import PropTypes from "prop-types";
import styles from "./DetailsHeader.module.css";

import Nursing from "./SpecialNeedsIcons/Nursing_Mother.svg";
import ADA from "./SpecialNeedsIcons/ADA_Seating.svg";
import HearingDevice from "./SpecialNeedsIcons/Hearing_Device.svg";
import SignLanguage from "./SpecialNeedsIcons/Sign_Language.svg";

import { ChevronLeft } from "react-ikonate";

/**
 * Header user on the ticket details page. A “Profile” link is found in the upper-left corner, which takes the user back to their WWG ID profile page. Centered at the top are any applicable special needs icons associated with the ticket.
 */
export default function DetailsHeader({ onClick, specialNeeds }) {
  return (
    <div className={styles.container} onClick={() => onClick()}>
      <div className={styles.header}>
        <ChevronLeft width={30} height={30} color={"#9E703C"}></ChevronLeft>{" "}
        Profile
        <div className={styles.specialNeeds}>
          {specialNeeds.map(
            (item) =>
              (item == "Nursing Mom" && (
                <img className={styles.icon} src={Nursing} alt={"Nursing Mom"} />
              )) ||
              (item == "ADA" && <img className={styles.icon}  src={ADA} alt={"ADA"} />) ||
              (item == "Hearing Device" && (
                <img className={styles.icon}  src={HearingDevice} alt={"Hearing Device"} />
              )) ||
              (item == "Sign Language" && (
                <img className={styles.icon}  src={SignLanguage} alt={"Sign Language"} />
              ))
          )}
        </div>
      </div>
      <div></div>
    </div>
  );
}

DetailsHeader.propTypes = {
  /**
   *Function called to take the user back to their WWG ID profile page.
   */
  onClick: PropTypes.func,
  /**
   * Array of strings with the special needs accommodations that the user chose when ordering their ticket.
   */
  specialNeeds: PropTypes.array,
};
