import PropTypes from "prop-types";
import styles from "./TicketList.module.css";
import TicketListHeader from "components/TicketListHeader/TicketListHeader";
import { useState } from "react";

import DateHeader from "components/DateHeader/DateHeader";
import Notification from "components/Notification/Notification";
import EventTicketItem from "components/EventTicketItem/EventTicketItem";
import LocationEventHeader from "components/LocationEventHeader/LocationEventHeader";

/**
 * List of sessions associated with the local function, along with the ticket-purchase status for those sessions.
 */
export default function TicketList({
  tickets,
  notifications,
  location,
  speaker,
  headerInfo,
  date,
}) {
  const [opened, setOpened] = useState(false);

  return (
    <div className={styles.container}>
      <TicketListHeader
        img={headerInfo.icon}
        title={headerInfo.title}
        onClick={() => setOpened(!opened)}
        opened={opened}
        bgColor={headerInfo.bgColor}
      />
      {opened && (
        <div>
          <DateHeader date={date} />
          {notifications.map((notification) => (
            <Notification
              notification={notification.notification}
              img={notification.img}
            />
          ))}
          <LocationEventHeader location={location} speaker={speaker} />
          <div>
            {tickets.map((ticket) => (
              <div className={styles.ticket}>
                <EventTicketItem
                  ticket={ticket.ticket}
                  ibo={ticket.ibo}
                  onView={() => console.log("View Ticket clicked")}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

TicketList.propTypes = {
  /**
   * Array of objects with the tickets Info (title-String, icon-String, date-String, qrCode-String, ticketId-String, name-String, img-String)
   */
  tickets: PropTypes.object,
  /**
   * Array of notification objects (img-String, notification-String)
   */
  notifications: PropTypes.object,
  /**
   * True if the header is open and list is displayed
   */
  open: PropTypes.bool,
  /**
   * Location displayed above tickets
   */
  location: PropTypes.string,
  /**
   * Names displayed above tickets
   */
  names: PropTypes.string,
  /**
   * Date for date header
   */
  Date: PropTypes.string,
  /**
   * Object with the info for the header (title-String, icon-String, bgColor-String)
   */
  headerInfo: PropTypes.object,
};
