import DetailsHeader from "components/DetailsHeader/DetailsHeader";
import styles from "./TicketDetails.module.css";
import { useHistory } from "react-router-dom";
import UserProfile from "components/UserProfile/UserProfile";
import TicketListHeader from "components/TicketListHeader/TicketListHeader";

import slIcon from "../../../assets/icons/Spring_Leadership_Icon.svg";
import AppleWallet from "../../../assets/icons/Add_to_Apple_Wallet_rgb_US-UK.png";

import DateHeader from "components/DateHeader/DateHeader";
import QRCode from "components/QRCode/QRCode";
import TicketDetailHeader from "components/TicketDetailHeader/TicketDetailHeader";

/**
 * Displays a list of receipt items in the receipts page
 */

export default function TicketDetails({ ...props }) {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <DetailsHeader
        specialNeeds={["Nursing Mom", "ADA", "Hearing Device"]}
        onClick={() => history.goBack()}
      />
      <TicketDetailHeader
        ibos={[
          {
            Name: "Susie Anderson",
            iboNum: "234567891",
            WWGNumber: "986453125",
            AvatarUrl: "https://i.pravatar.cc/300",
            status: "Platinum",
            eagle: "Double",
          },
          {
            Name: "Fenton-Ashton Anderson",
            iboNum: "234567891",
            WWGNumber: "986453124",
            AvatarUrl: "https://i.pravatar.cc/300",
            status: "Platinum",
            eagle: "Double",
          },
        ]}
        size={"Large"}
        onChangeImg={() => alert("Changed")}
        onViewOrders={() => alert("View Orders")}
        onProfileChange={() => alert("Profile Change")}
      />
      <TicketListHeader
        img={slIcon}
        title={"Spring Leadership - Las Vegas, NV"}
        onClick={() => alert("Clicked")}
        opened={null}
        bgColor={"#243746"}
      />
      <DateHeader date={"April 15–16, 2023"} />
      <div className={styles.appleWalletContainer}>
        <QRCode
          ticket={{
            qrCode: "123456",
            companyName: "Convention Center",
            location: "Spokane, WA",
            startDate: "2022-01-05 04:00:00",
            type: "SL",
          }}
        />
      </div>

      <div className={styles.appleWalletContainer}>
        <img
          className={styles.appleWallet}
          src={AppleWallet}
          alt={"Add To Apple Wallet"}
        />
      </div>
    </div>
  );
}
