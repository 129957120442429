import PropTypes from "prop-types";
import styles from "./Notification.module.css";
import classNames from "classnames";

/**
 * Displays a notification about an event
 */
export default function Notification({ img, notification, className }) {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.icon}>{img}</div>
      {notification}
    </div>
  );
}

Notification.propTypes = {
  /**
   * Img to display on the left
   */
  img: PropTypes.string,
  /**
   * Notification displayed
   */
  notification: PropTypes.string,
  /**
   * Classname to change the style of the card. Like the background color. The default BG color is #2A3746
   */
  className: PropTypes.string,
};
