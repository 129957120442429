// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconSpringLeadership({ className, size, color = "#97a3ae" }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 66.1 55.6" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_1578" data-name="Rectangle 1578" width="66.166" height="55.653" fill="none"/>
    </clipPath>
  </defs>
  <g id="Spring_Leadership" data-name="Spring Leadership" clipPath="url(#clip-path)">
    <path id="Path_7977" data-name="Path 7977" d="M64.826,39.679A26.178,26.178,0,0,1,60.7,46.893a11.234,11.234,0,0,1-4.535,3.195q-2.473.928-8.554.928H43.8q-5.153,0-6.081-.412a2.783,2.783,0,0,1-1.546-1.134,10.924,10.924,0,0,1-.618-4.432V11.543q0-4.741.618-5.978A4.741,4.741,0,0,1,38.545,3.5a14.635,14.635,0,0,1,5.978-.824V1.237H30.094a3.2,3.2,0,0,1-.721,1.237,2.267,2.267,0,0,1-1.649.618,9.276,9.276,0,0,1-3.092-1.031l-2.164-.824A20.715,20.715,0,0,0,15.562,0,15.665,15.665,0,0,0,4.432,4.123,13.089,13.089,0,0,0,0,14.017,12.367,12.367,0,0,0,1.443,20.1a16.387,16.387,0,0,0,4.432,5.05,80.182,80.182,0,0,0,9.688,6.081,96.366,96.366,0,0,1,8.348,5.05,11.852,11.852,0,0,1,3.4,3.71,9.379,9.379,0,0,1,.721,1.752,7.421,7.421,0,0,1,.309,2.267,7.627,7.627,0,0,1-.412,2.37,8.451,8.451,0,0,1-2.473,3.71,11.131,11.131,0,0,1-7.73,2.473,16.284,16.284,0,0,1-7.833-1.855,13.192,13.192,0,0,1-5.256-4.741,25.765,25.765,0,0,1-2.783-8.554H.515V55.654H1.958a4.741,4.741,0,0,1,.824-2.473,2.061,2.061,0,0,1,1.546-.618A22.364,22.364,0,0,1,9.173,53.8l5.05,1.443a25.559,25.559,0,0,0,4.329.309,19.376,19.376,0,0,0,7.008-1.237H61.631l4.535-14.429ZM19.479,23.5q-9.172-4.844-11.543-7.73a6.7,6.7,0,0,1-1.649-4.432A7.523,7.523,0,0,1,8.863,5.669a9.482,9.482,0,0,1,6.8-2.577,14.429,14.429,0,0,1,7.008,1.855,13.192,13.192,0,0,1,5.153,4.947l.206.309V28.548A95.4,95.4,0,0,0,19.479,23.6" transform="translate(0 0)" fill={color}/>
  </g>
    </svg>
  );
}

IconSpringLeadership.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
