// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconFreeEnterprise({ className, size, color = "#97a3ae" }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 77.8 54" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group_5231" data-name="Group 5231">
        <path id="Path_7968" data-name="Path 7968" d="M15.372,54H0V52.526H1.912q3.345,0,4.859-1.951.954-1.273.955-6.132V9.557a17.872,17.872,0,0,0-.518-5.415A3.588,3.588,0,0,0,5.576,2.43a7.4,7.4,0,0,0-3.664-.956H0V0H40.182L40.7,11.867H39.306A16.346,16.346,0,0,0,36.9,6.312a7.922,7.922,0,0,0-3.385-2.569,18.075,18.075,0,0,0-6.232-.8H15.372V24.173H20.58q3.382,0,4.958-1.494t2.09-5.914H29.1V35.044H27.628a10.69,10.69,0,0,0-.816-4.619,5.173,5.173,0,0,0-2.15-2.211,8.907,8.907,0,0,0-4.082-.736H15.372V54Z" fill={color}/>
      </g>
      <g id="Group_5232" data-name="Group 5232">
        <path id="Path_7969" data-name="Path 7969" d="M46.949,2.947V24.292H58.816q4.62,0,6.172-1.394,2.072-1.832,2.31-6.451h1.474v18.8H67.3a20.558,20.558,0,0,0-1.115-5.057A4.983,4.983,0,0,0,63.834,28a12,12,0,0,0-5.018-.8H46.949V45a16.5,16.5,0,0,0,.319,4.361A2.5,2.5,0,0,0,48.383,50.6a6.755,6.755,0,0,0,3.026.458h9.159a24.815,24.815,0,0,0,6.651-.637A9.919,9.919,0,0,0,71.2,47.907a30.675,30.675,0,0,0,5.057-7.447h1.593L73.193,54H31.577V52.526h1.912a7.571,7.571,0,0,0,3.624-.915A3.354,3.354,0,0,0,38.845,49.7a18.749,18.749,0,0,0,.458-5.217V9.4q0-5.137-1.035-6.331-1.434-1.593-4.779-1.593H31.577V0H73.193l.6,11.828H72.237a18.686,18.686,0,0,0-1.852-5.855,6.365,6.365,0,0,0-3.007-2.429,18.045,18.045,0,0,0-5.615-.6Z" fill={color}/>
      </g>
    </svg>
  );
}

IconFreeEnterprise.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
