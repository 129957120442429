import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import styles from "./TicketItem.module.css";
import ViewTicketButton from "components/ViewTicketButton/ViewTicketButton";
import exampleQR from "../../assets/icons/QR_Code_Icon.svg";

/**
 * Button used in the ticket item component to view the ticket
 */
export default function TicketItem({ ibo, onClick = () => {}, ticketInfo }) {
  const history = useHistory();

  const handleViewTicketClick = () => {
    onClick("Clicked"); // Optional: Trigger the onClick function passed as a prop
    history.push("/ticket/details"); // Navigate to /ticket/details using history.push
  };

  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <img className={styles.img} src={ibo.img} alt="Avatar" />
      </div>
      <ViewTicketButton qrCode={exampleQR} onClick={handleViewTicketClick} />
      <div className={styles.name}>{ibo.name}</div>
    </div>
  );
}

TicketItem.propTypes = {
  ibo: PropTypes.object.isRequired,
  onClick: PropTypes.func, // Optional function prop type
  ticketInfo: PropTypes.object.isRequired,
};
