import PropTypes from "prop-types";
import styles from "./EventOrdersButton.module.css";
import icon from "../../assets/icons/Event_Orders_Icon.svg"

/**
 * Button at the bottom of the profile header component which links to the user’s Receipts summary page. 
 */
export default function EventOrdersButton({ onClick }) {
  return <div className={styles.btn} onClick={() => onClick()}><img className={styles.icon} src={icon} alt={"Event Orders Icon"} />My Event Orders</div>;
}

EventOrdersButton.propTypes = {
  /**
   * Function called when the btn is clicked
   */
  onClick: PropTypes.func,
};
