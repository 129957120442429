import env from "./environment";
import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";

export let token = null;

/*==============================*\
||   Init auth at app startup   ||
\*==============================*/

// Try pulling token from localStorage first.
token = localStorage.getItem("wwg-token");

// If not present, try extracting value from cookie.
if (!token) {
  const cookies = document.cookie.split(";").reduce((res, c) => {
    const [key, val] = c.trim().split("=").map(decodeURIComponent);
    const allNumbers = (str) => /^\d+$/.test(str);
    try {
      return Object.assign(res, {
        [key]: allNumbers(val) ? val : JSON.parse(val),
      });
    } catch (e) {
      return Object.assign(res, { [key]: val });
    }
  }, {});

  if (cookies["wwg-token"]) {
    token = cookies["wwg-token"];
  }
}

/*==============================*\
||     Main hook & container    ||
\*==============================*/

function useAuth() {
  const [_token, _setToken] = useState(null);

  // Check for token on mount.
  useEffect(() => {
    if (token) {
      _setToken(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    token: _token,
    setToken: _setToken,
    isLoggedIn: token != null,
  };
}

// Export Auth as a container so we can share one instance across multiple components.
export const Auth = createContainer(useAuth);

/*==============================*\
||      Utility components      ||
\*==============================*/

/**
 * Nest routes inside of this component to make sure they're only accessible
 * when the user is logged in. There should be one of these at the top level
 * of most apps.
 */
export function LoginGuard({ children }) {
  const { isLoggedIn } = Auth.useContainer();

  if (isLoggedIn) {
    return children;
  } else if (env.localDevMode) {
    return <LocalLoginComponent />;
  } else {
    // Redirect user to get auth token from Core API.
    const url = new URL(
      `/v1/auth/token-redirect?r=${window.location.href}`,
      env.coreApiUrl
    );

    url.protocol = "https";

    window.location.replace(url);
  }
}

/**
 * Allows developers to log in conveniently while developing locally.
 */
function LocalLoginComponent() {
  const { setToken } = Auth.useContainer();
  const [error, setError] = useState(null);

  return (
    <form
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 999,
        backgroundColor: "#fff",
      }}
      onSubmit={async (e) => {
        e.preventDefault();

        const username = e.target.username.value;
        const password = e.target.password.value;

        const url = new URL(`/v1/auth/login`, env.coreApiUrl);

        const res = await fetch(url, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username,
            password,
          }),
        });

        if (res.ok) {
          const json = await res.json();

          setError(null);

          // Set module-level 'token' variable and Auth container token.
          token = json.token;
          setToken(json.token);

          localStorage.setItem("wwg-token", json.token);
        } else {
          if (res.status === 403) {
            setError("403: Incorrect username or password");
          } else {
            setError(res.status);
          }
        }
      }}
    >
      <p>
        You can generate temporary credentials at{" "}
        <a
          href="https://dev.wwg.support/membership/lookup"
          target="_blank"
          rel="noreferrer"
        >
          dev.wwg.support
        </a>
        .
      </p>
      {error && (
        <p style={{ padding: "0.5em", color: "#fff", backgroundColor: "red" }}>
          {error}
        </p>
      )}
      <input type="text" name="username" placeholder="Temp Username" />
      <br />
      <input type="password" name="password" placeholder="Temp Password" />
      <button>Log In</button>
    </form>
  );
}
