// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconFamilyReunion({ className, size, color = "#97a3ae" }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 73.5 55.5" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path id="Family_Reunion" data-name="Family Reunion" d="M66.163,51.816c-1.884-1.2-4.952-4.809-7.575-8.473L47.9,28.551c2.933-.6,7.424-2.083,10.365-5.333a12.777,12.777,0,0,0,3.346-8.9,12.382,12.382,0,0,0-2.933-8.266,15,15,0,0,0-7.137-4.761,39.1,39.1,0,0,0-9.6-1.232h0L38.471,0H.533L0,12.224H1.439A16.676,16.676,0,0,1,3.919,6.5,8.176,8.176,0,0,1,7.408,3.855a18.61,18.61,0,0,1,6.422-.819H24.76a2.552,2.552,0,0,1,.294.286c.533.588.882,1.836,1.042,3.728V24.9H15.961q-3.482,0-5.1-1.542c-1.081-1.025-1.8-3.051-2.153-6.087H7.186V36.1H8.7a11.007,11.007,0,0,1,.833-4.761,5.378,5.378,0,0,1,2.218-2.274,9.113,9.113,0,0,1,4.205-.763H26.1V45.79a18.626,18.626,0,0,1-.524,5.54,1.033,1.033,0,0,1-.057.143.588.588,0,0,1-.055.127.187.187,0,0,1-.032.048c-.04.071-.08.142-.128.214a2.089,2.089,0,0,1-.167.238,5.707,5.707,0,0,1-4.84,2.011H18.2v1.519H41.936V54.112H39.862a7.5,7.5,0,0,1-.755-.032,7.748,7.748,0,0,1-1.359-.223c-.008,0-.008,0-.016-.007a3.87,3.87,0,0,1-.6-.191,2.884,2.884,0,0,1-.484-.215c-.032-.015-.072-.032-.1-.047a4.631,4.631,0,0,1-1.36-1.089c-.771-.843-1.153-3.021-1.153-6.518V28.233c.438.024.866.047,1.272.056s.794.024,1.153.024c.874,0,2.925-.056,4.316-.111L58.7,55.631H73.547V54.112A16.976,16.976,0,0,1,66.163,51.816ZM36.332,24.9c-.246,0-.556-.007-.946-.024s-.834-.015-1.352-.015V7.344a10.988,10.988,0,0,1,.477-3.084,3.134,3.134,0,0,1,.263-.5,33.694,33.694,0,0,1,5.707-.644,11.678,11.678,0,0,1,8.631,3.26,11.456,11.456,0,0,1,3.268,8.472c0,3.561-2.528,5.994-4.047,7.042C45.418,23.9,41.642,24.9,36.332,24.9Z" fill={color}/>
    </svg>
  );
}

IconFamilyReunion.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
