import styles from "./ReceiptsSummary.module.css";
import { useHistory } from "react-router-dom";

import testIcon from "../../../assets/icons/Seminar_Icon_Ticket.svg";
import filterLines from "../../../assets/icons/Filter_Lines.svg";
import ReceiptList from "components/ReceiptList";
import DetailsHeader from "components/DetailsHeader/DetailsHeader";
/**
 * Displays a list of receipt items in the receipts page
 */

export default function ReceiptsSummary({ ...props }) {
  const history = useHistory();

  const receipts = [
    {
      icon: testIcon,
      name: "Regional Rally Meeting™",
      ticketNum: "894523",
      purchaseDate: "June 1, 2022",
      purchaseTime: "10:39 p.m. (PT)",
      total: "86.96",
    },
    {
      icon: testIcon,
      name: "Regional Rally Meeting™",
      ticketNum: "894523",
      purchaseDate: "June 1, 2022",
      purchaseTime: "10:39 p.m. (PT)",
      total: "86.96",
    },
    {
      icon: testIcon,
      name: "Regional Rally Meeting™",
      ticketNum: "894523",
      purchaseDate: "June 1, 2022",
      purchaseTime: "10:39 p.m. (PT)",
      total: "86.96",
    },
    {
      icon: testIcon,
      name: "Regional Rally Meeting™",
      ticketNum: "894523",
      purchaseDate: "June 1, 2022",
      purchaseTime: "10:39 p.m. (PT)",
      total: "86.96",
    },
    {
      icon: testIcon,
      name: "Regional Rally Meeting™",
      ticketNum: "894523",
      purchaseDate: "June 1, 2022",
      purchaseTime: "10:39 p.m. (PT)",
      total: "86.96",
    },
  ];

  return (
    <div className={styles.container}>
      <DetailsHeader specialNeeds={[]} onClick={() => history.goBack()} />
      <img src={filterLines} alt={"Filter Menu Icon"} />
      <ReceiptList receipts={receipts} />
    </div>
  );
}
