import ReceiptItem from "components/ReceiptItem";
import styles from "./ReceiptList.module.css";
import PropTypes from "prop-types";

/**
 * Displays a list of receipt items in the receipts page
 */

export function ReceiptList({ receipts }) {
  return (
    <div className={styles.container}>
      {receipts.map((receipt) => (
        <ReceiptItem info={receipt} onClick={() => alert("Clicked")} />
      ))}
    </div>
  );
}

ReceiptList.propTypes = {
  /**
   * Array of receipt objects
   */
  receipts: PropTypes.array,
};
