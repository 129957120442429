import React from "react";
import Select from "react-select";

import styles from "./SelectContact.module.css";
import chevron from "./Chevrons.svg"; 

export default function SelectContact({
  contactOptions,
  selectedContact,
  setSelectedContact,
  ...props
}) {
  const selectOptions = contactOptions.map((contact) => {
    return { value: contact, label: contact.Name };
  });

  const customStyles = {
    container: () => ({}),
    control: () => ({}),
    valueContainer: () => ({
      display: "none",
    }),
    singleValue: () => ({}),
    indicatorSeparator: () => ({}),
    dropdownIndicator: () => ({
      padding: "2px",
      cursor: "pointer",
      display: "flex",
    }),
    menu: (provided) => ({
      ...provided,
      left: "0",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#ccc" : "#fff",
      color: "#000",
      cursor: "pointer",
    }),
  };

  const DropdownIndicator = (props) => {
    return (
      <div
        {...props.innerProps}
        className={styles.dropdownIndicator}
        style={{ marginTop: "5px" }} 
      >
        <img src={chevron} alt="Dropdown Indicator" />
      </div>
    );
  };

  return (
    <Select
      defaultValue={{ value: selectedContact, label: selectedContact.Name }}
      onChange={(option) => setSelectedContact(option.value)}
      options={selectOptions}
      isSearchable={false}
      className={styles["contact-select"]}
      styles={customStyles}
      components={{ DropdownIndicator }} 
    />
  );
}
