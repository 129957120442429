import { MobileBridge } from "@wwg/mobile-bridge";

const mobile = new MobileBridge({
  verbose: true,
});

// if (process.env.NODE_ENV === "development") {
//   mobile.mock({
//     platform: "and",
//   });
// }

export default mobile;
