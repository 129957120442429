import PropTypes from "prop-types";
import styles from "./ViewTicketButton.module.css";

/**
 * Button used in the ticket item component to view the ticket
 */
export default function ViewTicketButton({ qrCode, onClick }) {
  return <div className={styles.btn} onClick={() => onClick()}><img className={styles.qr} src={qrCode} alt={"QR Code"} />View Ticket</div>;
}

ViewTicketButton.propTypes = {
  /**
   *  Src for the qr code
   */
  qrCode: PropTypes.string,
  /**
   * Function called when the btn is clicked
   */
  onClick: PropTypes.func,
};
