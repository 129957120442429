import { useQuery, useQueryClient, useMutation } from "react-query";
import { makeAuthedRequest } from "utils/makeAuthedRequest";
import { useContainer } from "unstated-next";
import TicketContext from "../../../stores/TicketContext";
import { useMemo } from "react";
import mobile from "shared/mobileBridge";

/**
 *  QUERY events: useEventsData() -> [{...event}, ...]
 *  UPDATE events: useEventsData.updateEventsMutation([{...event},...])
 */

export const ticketEndPoints = {
  // TODO: Check these before deployment
  get: {
    loadTickets: "/api/tickets/",
  },
//   post: {
//     addTicket: "/api/cart/ticket/",
//   },
//   put: {
//     ticket: "/api/cart/ticket/",
//     ticketOptions: "/api/cart/ticket/",
//     addTime: "/api/cart/time/",
//   },
//   delete: {
//     ticket: "/api/cart/ticket/",
//   },
};

export function useTicketData({contactId}) {
  // export function useTicketData() {
  // let { contactId } = useContainer(TicketContext);
  const queryClient = useQueryClient();

  const isIOS = (mobile.getPlatform() || "").toLowerCase() == "ios"// || true
  const isAndroid = (mobile.getPlatform() || "").toLowerCase() == "and" || (mobile.getPlatform() || "").toLowerCase() == "android"
  var platform = "web"
  if(isIOS){
    platform = "ios"
  }
  if(isAndroid){
    platform = "android"
  }

  const { isLoading, isSuccess, isError, data, error, fetch, refetch } =
    useQuery(["tickets", contactId], () =>
      makeAuthedRequest(
        `${ticketEndPoints.get.loadTickets}`,
        { method: "get", headers: { "wwg-contact-id": contactId, "platform": platform} }
      ).then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          return json;
        } else {
          throw new Error(json.message);
        }
      })
    );
    
  const tickets = useMemo(
    () => (data ? data : []),
    [data]
  );

//   const addTicketsMutation = useMutation(
//     ({ eventId, qty }) =>
//       makeAuthedRequest(
//         `${cartEndPoints.post.addTicket}?purchaseTypeId=${purchaseTypeId}?year=${year}`,
//         {
//           method: "post",
//           body: JSON.stringify({
//             eventId,
//             qty,
//           }),
//         }
//       ).then(async (res) => {
//         if (!res.ok) {
//           const json = await res.json();
//           if (json.errors && json.errors.length > 0) {
//             throw new Error(json.errors[0].message);
//           }
//         }
//       }),
//     {
//       onSettled: () => {
//         queryClient.invalidateQueries("cart");
//       },
//     }
//   );

  return {
    isLoading,
    isSuccess,
    isError,
    data,
    error,
    fetch,
    refetch,
    tickets
  };
}
