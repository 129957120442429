import PropTypes from "prop-types";
import styles from "./LocationEventHeader.module.css";

/**
 * Circular badge to display a text or number
 */
export default function LocationEventHeader({ location, speaker }) {
  return (
    <div className={styles.container}>
      {" "}
      <div className={styles.locationNameHeader}>
        <div className={styles.location}>{location}</div>
        <div className={styles.names}>{speaker}</div>
      </div>
    </div>
  );
}

LocationEventHeader.propTypes = {};
