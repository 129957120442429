import React from 'react'
import styles from './Contact.module.css';
import mobile from "shared/mobileBridge";
import ContactImage from 'components/ContactImage/ContactImage';
import SelectContact from 'components/SelectContact/SelectContact';
import classNames from 'classnames';

const pinLevels = require("_enums/pinLevel");

export default function Contact({contact, pinLevel, iboNumber, contactOptions, setSelectedContact, updateAvatar, onCloseClicked, showIconClose = false, isCheckingIn = false, isADA = false, isNursingMother = false, ...props}){

  function pinLevelName() {
    if (pinLevel === pinLevels.Platinum) {
      return 'Platinum';
    } else if (pinLevel === pinLevels.Emerald) {
      return 'Emerald';
    } else if (pinLevel === pinLevels.Diamond) {
      return 'Diamond';
    }
    return 'IBO';
  }

  function firstName() {
    const name = contact.Name;
    return name ? name.split(' ')[0] : '';
  }

  function lastName() {
    const name = contact.Name;
    if (name) {
      const parts = name.split(' ');
      return parts.length === 2 ? parts[1] : '';
    }
    return '';
  }

  function onMobileSelectContact() {
    const pickerOptions = contactOptions.map((contact) => {
      return {
        ContactId: contact.WWGNumber,
        Name: contact.Name,
        AvatarUrl: contact.AvatarUrl,
      }
    })
    mobile.postMessage(
        "select-contact-picker", 
        {
            title: 'Select User',
            options: pickerOptions
        },
        true
    ).then((contactId) => {
        const selectedContact = contactOptions.find((contact) => contact.WWGNumber === contactId);
        if (selectedContact) {
            setSelectedContact(selectedContact);
        }
    });
  }

  return (
    <div className={[styles["contact"], styles[`${pinLevelName()}`]].join(" ")}>
      <ContactImage contact={contact} isADA={isADA} isNursingMother={isNursingMother} pinLevel={pinLevel} updateAvatar={updateAvatar} onCloseClicked={onCloseClicked} showIconClose={showIconClose} />
      <div className={classNames([styles["contact-info"], styles["contact-info-" + pinLevelName()]])}>
        <div className={[styles["name"], (contactOptions.length > 1) ? styles['picker'] : ''].join(" ")}>
          <h2>
            {/* {!!firstName() && (<strong>{firstName()}&nbsp;</strong>)}
            {!!lastName() && lastName()} */}
            {contact.Name}
          </h2>
          {(contactOptions.length > 1) && (
            mobile.isApp() ? (
              <button className={styles["btn", "contact-select"]} onClick={onMobileSelectContact}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" fill="#ffffff" focusable="false" className={styles["css-6q0nyr-Svg"]}><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
              </button>
            ) : (
                <SelectContact contactOptions={contactOptions} selectedContact={contact} setSelectedContact={setSelectedContact} />
              )
          )}
        </div>
        {!isCheckingIn && <div>
          <div className={classNames([styles["pin-level"], styles["pin-level-" + pinLevelName()]])}>
            {pinLevelName()}
          </div>
          <hr />
          <div className={styles["contact-numbers"]}>
            <div className={classNames([styles["ibo-number"], styles["label-" + pinLevelName()]])}><span className={styles["label"]}>IBO#</span><span className={styles["label"]}>{iboNumber}</span></div>
            <div className={classNames([styles["wwg-number"], styles["label-" + pinLevelName()]])}><span className={styles["label"]}>WWG#</span><span className={styles["label"]}>{contact.WWGNumber}</span></div>
          </div>
        </div>}
      </div>
    </div>
  )
}