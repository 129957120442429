import { DomainConf } from "@wwg/domain-conf";

/**
 * Define configurations here to be loaded based on
 * which domain the app is being served from.
 */
const config = new DomainConf()
  .defaults({
    lang: "en",
    dateFormat: "MMMM D[,] YYYY",
    dateTimeFormat: "MMMM D[,] YYYY [at] h[:]mm a",
    vcsMonthFormat: "MMMM YYYY",
  })
  .forDomain("localhost", {
    envName: "dev",
    // lang: "ja",
    // apiBase: "http://localhost:3750",
    apiBase: "https://app-id-card-api-dev.wwdb.com",
    coreApiUrl: "https://app-core-web-dev.wwdb.com",
    localDevMode: true, // Set to true to enable local login for developers.
    // dateFormat: "YYYY[年]MM[月]DD[日]",
    // dateTimeFormat: "YYYY[年]MM[月]DD[日] H[:]mm",
    purchaseUrl: "https://app-events-sales-web-dev.wwdb.com",
    receiptUrl: "https://app-receipts-web-dev.wwdb.com",
  })
  // North America
  .forDomain("app-id-card-web-local.wwdb.com", {
    envName: "dev",
    localDevMode: true, // Set to true to enable local login for developers.
    apiBase: "https://app-id-card-api-dev.wwdb.com",
    coreApiUrl: "https://app-core-web-dev.wwdb.com",
    rootDomain: ".wwdb.com",
    purchaseUrl: "https://app-events-sales-web-dev.wwdb.com",
    receiptUrl: "https://app-receipts-web-dev.wwdb.com",
  })
  .forDomain("dev-ca-id-card.wwdb.com", {
    envName: "dev",
    apiBase: "https://app-id-card-api-dev.wwdb.com",
    coreApiUrl: "https://app-core-web-dev.wwdb.com",
    rootDomain: ".wwdb.com",
    purchaseUrl: "https://app-events-sales-web-dev.wwdb.com",
    receiptUrl: "https://app-receipts-web-dev.wwdb.com",
  })
  .forDomain("app-id-card-web-staging.wwdb.com", {
    envName: "staging",
    apiBase: "https://app-id-card-api-staging.wwdb.com",
    coreApiUrl: "https://app-core-web-staging.wwdb.com",
    rootDomain: ".wwdb.com",
    purchaseUrl: "https://app-events-sales-web-staging.wwdb.com",
    receiptUrl: "https://app-receipts-web-staging.wwdb.com",
  })
  .forDomain("app-id-card-web.wwdb.com", {
    envName: "production",
    apiBase: "https://app-id-card-api.wwdb.com",
    coreApiUrl: "https://app-core-web.wwdb.com",
    rootDomain: ".wwdb.com",
    purchaseUrl: "https://app-events-sales-web.wwdb.com",
    receiptUrl: "https://app-receipts-web.wwdb.com",
  })
  // Australia & New Zealand
  .forDomain("app-id-card-web-dev.wwdb.com.au", {
    envName: "dev",
    apiBase: "https://app-id-card-api-dev.wwdb.com.au",
    coreApiUrl: "https://app-core-web-dev.wwdb.com.au",
    rootDomain: ".wwdb.com.au",
    purchaseUrl: "https://app-events-sales-web-dev.wwdb.com.au",
    receiptUrl: "https://app-receipts-web-dev.wwdb.com.au",
  })
  .forDomain("app-id-card-web-staging.wwdb.com.au", {
    envName: "staging",
    apiBase: "https://app-id-card-api-staging.wwdb.com.au",
    coreApiUrl: "https://app-core-web-staging.wwdb.com.au",
    rootDomain: ".wwdb.com.au",
    purchaseUrl: "https://app-events-sales-web-staging.wwdb.com.au",
    receiptUrl: "https://app-receipts-web-staging.wwdb.com.au",
  })
  .forDomain("app-id-card-web.wwdb.com.au", {
    envName: "production",
    apiBase: "https://app-id-card-api.wwdb.com.au",
    coreApiUrl: "https://app-core-web.wwdb.com.au",
    rootDomain: ".wwdb.com.au",
    purchaseUrl: "https://app-events-sales-web.wwdb.com.au",
    receiptUrl: "https://app-receipts-web.wwdb.com.au",
  });

// Export the selected configuration from this file. You can import this wherever you need to use it.
export default config.current;
