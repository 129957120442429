// This is a generated file. Manual changes will be overwritten.

import React from "react";
import PropTypes from "prop-types";

export function IconLeadershipSummit({ className, size, color = "#97a3ae" }) {
  const s = size || "1em";

  const attr = { version: "1.1", viewBox: "0 0 36 36" };

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...attr}
      className={className}
      height={s}
      width={s}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
    <clipPath id="clip-path">
      <path id="Path_3558" data-name="Path 3558" d="M18,0A18,18,0,1,1,0,18,18,18,0,0,1,18,0Z" fill="#243746"/>
    </clipPath>
  </defs>
  <g id="Leadership_Summit_Icon" transform="translate(1497 -1219)">
    <g id="Group_3359" data-name="Group 3359" transform="translate(-1497 1219)">
      <path id="Path_3558-2" data-name="Path 3558" d="M18,0A18,18,0,1,1,0,18,18,18,0,0,1,18,0Z" fill="#243746"/>
    </g>
    <g id="Mask_Group_4" data-name="Mask Group 4" transform="translate(-1497 1219)" clip-path="url(#clip-path)">
      <g id="Group_15954" data-name="Group 15954" transform="translate(-18.605 8.56)">
        <path id="Path_40589" data-name="Path 40589" d="M40.441,4.061C19.284,13.836,3.713,20.783.433,21.921c-.284.1-.417.133-.432.1C-.253,21.5,38.028,3.156,38.158,3.1l.108-.044.063-.027c.023,0,.051.009.085.017l.056.014c.052.014.865.422,1.972,1" transform="translate(0 -3.032)" fill="#84929e"/>
        <path id="Path_40590" data-name="Path 40590" d="M45.861,6.742c-16.718,7.247-29,12.522-32.127,13.813a1.745,1.745,0,0,1-.489.174C13.1,20.444,43.212,5.932,43.361,5.87l.124-.051.073-.031.092.013.064.012c.058.011.942.391,2.148.928" transform="translate(-1.69 -3.384)" fill="#84929e"/>
        <path id="Path_40591" data-name="Path 40591" d="M51.266,9.66c-13.161,5.5-22.8,9.481-24.855,10.279-.157.061-.276.106-.284.088C26,19.779,48.621,8.839,48.77,8.777l.124-.051.072-.03.093.013.062.012c.058.012.941.4,2.144.938" transform="translate(-3.333 -3.755)" fill="#84929e"/>
        <path id="Path_40592" data-name="Path 40592" d="M55.907,12.227c-10.019,4.2-17.408,7.191-19.208,7.79-.151.051-.269.085-.283.059-.166-.327,16.847-8.665,16.992-8.724l.124-.051.072-.031.092.013.063.012c.058.011.942.393,2.147.931" transform="translate(-4.646 -4.083)" fill="#84929e"/>
        <path id="Path_40593" data-name="Path 40593" d="M60.406,14.875c-7.857,2.885-13.588,4.98-14.78,5.421-.082.03-.155.058-.162.043-.1-.189,12.321-6.36,12.473-6.416l.125-.047.073-.029c.024,0,.056.009.092.017l.063.014c.057.013.929.421,2.116,1" transform="translate(-5.801 -4.412)" fill="#84929e"/>
      </g>
    </g>
    <path id="Path_40594" data-name="Path 40594" d="M42.885,16.875c-3.448,0-4.8-.822-5.8-.822a.844.844,0,0,0-.822.48c-.068.137-.137.183-.228.183-.114,0-.205-.069-.251-.252A48.593,48.593,0,0,0,34,11.349a.658.658,0,0,1-.046-.206c0-.137.068-.182.159-.182.114,0,.206.067.343.273,1.918,2.992,4.91,3.928,8.448,3.928,2.4,0,4.019-.823,4.019-2.878,0-4.635-11.92-.388-11.92-7.33,0-2.762,2.329-4.7,6.325-4.7a43.652,43.652,0,0,1,4.475.456.7.7,0,0,0,.8-.547A.221.221,0,0,1,46.836,0c.114,0,.183.069.251.252.5,1.575,1.051,3.2,1.667,4.726a.454.454,0,0,1,.046.229.162.162,0,0,1-.159.182c-.115,0-.206-.068-.343-.3C46.881,2.831,44.8,1.964,41.446,1.964c-3.037,0-4.064,1.187-4.064,2.7,0,4.224,12.057.114,12.057,7.307,0,3.677-3.266,4.91-6.554,4.91" transform="translate(-1520.698 1228.563)" fill="#fff"/>
  </g>
    </svg>
  );
}

IconLeadershipSummit.propTypes = {
  /**
   * Size in pixels (or a string with the size in CSS units)
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * A CSS color string.
   */
  color: PropTypes.string,
};
