import { createContainer } from "unstated-next";
import { useState } from "react";

/**
 * React context store that provides data required for routes and pages.
 */

function useTicketDataHook(initialState = {}) {

  let [value, setValue] = useState(initialState);
  let setContactId = (contactId) => setValue({...value, contactId })
  let setTickets = (tickets) => setValue({...value, tickets })

  return {
    value,
    contactId: value.contactId,
    setContactId: setContactId,
    tickets: value.tickets,
    setTickets: setTickets,
  };
}

export default createContainer(useTicketDataHook);
