import PropTypes from "prop-types";
import { useState } from "react";
import styles from "./TicketDetailHeader.module.css";
import mobile from "shared/mobileBridge";
import SelectContact from "components/SelectContact/SelectContact";
import EventOrdersButton from "components/EventOrdersButton/EventOrdersButton";

/**
 * Allows user to switch profiles, view event orders, change profile img, and displays their info.
 */
export default function TicketDetailHeader({
  ibos,
  size,
  onChangeImg,
  onViewOrders,
  onProfileChange,
}) {
  const [selectedContact, setSelectedContact] = useState(ibos[0]);

  function onMobileSelectContact() {
    const pickerOptions = ibos.map((contact) => ({
      ContactId: contact.WWGNumber,
      Name: contact.Name,
      AvatarUrl: contact.AvatarUrl,
    }));
    mobile
      .postMessage(
        "select-contact-picker",
        {
          title: "Select User",
          options: pickerOptions,
        },
        true
      )
      .then((contactId) => {
        const selectedContact = ibos.find(
          (contact) => contact.WWGNumber === contactId
        );
        if (selectedContact) {
          setSelectedContact(selectedContact);
        }
      });
  }

  return (
    <div>
      {size === "Large" ? (
        <div className={styles.container}>
          <div
            className={[styles.name, ibos.length > 1 ? styles.picker : ""].join(
              " "
            )}
          >
            <div className={styles.iboName}>{selectedContact.Name}</div>
            {ibos.length > 1 &&
              (mobile.isApp() ? (
                <button
                  className={styles["contact-select"]}
                  onClick={onMobileSelectContact}
                >
                  <svg
                    height="20"
                    width="20"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                    fill="#ffffff"
                    focusable="false"
                    className={styles["css-6q0nyr-Svg"]}
                  >
                    <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                  </svg>
                </button>
              ) : (
                <SelectContact
                  contactOptions={ibos}
                  selectedContact={selectedContact}
                  setSelectedContact={setSelectedContact}
                />
              ))}
          </div>
          <div className={styles.imgContainer}>
            <img
              className={styles.img}
              src={selectedContact.AvatarUrl}
              alt="Avatar"
            />
          </div>
          <div>
            <div className={styles.iboLabel}>
              <div>IBO</div>
            
            </div>
            <div className={styles.numsContainer}>
              <div className={styles.iboNum}>
                <div>IBO#: {selectedContact.iboNum}</div>
              </div>
              <div className={styles.wwgID}>
                <div>WWG#: {selectedContact.WWGNumber}</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div
            className={[
              styles.name2,
              ibos.length > 1 ? styles.picker2 : "",
            ].join(" ")}
          >
            <h2>{selectedContact.Name}</h2>
            {ibos.length > 1 &&
              (mobile.isApp() ? (
                <button
                  className={styles["contact-select"]}
                  onClick={onMobileSelectContact}
                >
                  <svg
                    height="20"
                    width="20"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                    fill="#ffffff"
                    focusable="false"
                    className={styles["css-6q0nyr-Svg"]}
                  >
                    <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                  </svg>
                </button>
              ) : (
                <SelectContact
                  contactOptions={ibos}
                  selectedContact={selectedContact}
                  setSelectedContact={setSelectedContact}
                />
              ))}
          </div>
          <div>
            <div className={styles.centerContainer}>
              <div className={styles.iboNum}>
                <div>IBO#: {selectedContact.iboNum}</div>
              </div>
              <div className={styles.numsContainer2}>
                {/* <div >IBO</div> */}
                <div className={styles.iboNum2}>
                  <div>IBO#: {selectedContact.iboNum}</div>
                </div>
                <div className={styles.wwgID}>
                  <div>WWG#: {selectedContact.WWGNumber}</div>
                </div>
              </div>
              <div className={styles.imgContainer2}>
                <img
                  className={styles.img2}
                  src={selectedContact.AvatarUrl}
                  alt="Avatar"
                />
              </div>
            </div>
            <div className={styles.footer}>
              <EventOrdersButton onClick={onViewOrders} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

TicketDetailHeader.propTypes = {
  ibos: PropTypes.arrayOf(
    PropTypes.shape({
      Name: PropTypes.string.isRequired,
      iboNum: PropTypes.string.isRequired,
      WWGNumber: PropTypes.string.isRequired,
      AvatarUrl: PropTypes.string,
      eagle: PropTypes.string,
    })
  ),
  size: PropTypes.oneOf(["small", "Large"]),
  onChangeImg: PropTypes.func,
  onViewOrders: PropTypes.func,
  onProfileChange: PropTypes.func,
};
