import styles from "./QRCode.module.css"

import { useQRCode } from 'react-qrcodes';
import {IconSpringLeadership} from "components/Icons/IconSpringLeadership";
import * as moment from "moment-timezone";
import { IconFamilyReunion } from "components/Icons/IconFamilyReunion";
import { IconFreeEnterprise } from "components/Icons/IconFreeEnterprise";
import env from "environment";

import mobile from "shared/mobileBridge";
import { IconDoubleEagle } from "components/Icons/IconDoubleEagle";
import { IconEagle } from "components/Icons/IconEagle";
import { IconAchievers } from "components/Icons/IconAchievers";
import { IconLeadershipSummit } from "components/Icons/IconLeadershipSummit";

export default function QRCode({ticket, ...props}){
    const [qrCode] = useQRCode({
        text: ticket.qrCode,
        options: {
        level: 'H',
        margin: 0,
        scale: 8,
        // width: 200,
        color: {
            dark: '#000000',
            light: '#ffffff',
        },
        },
    })

    const isApp = mobile.isApp()// || true;
    const isIOS = (mobile.getPlatform() || "").toLowerCase() == "ios"// || true
    const isAndroid = (mobile.getPlatform() || "").toLowerCase() == "and" || (mobile.getPlatform() || "").toLowerCase() == "android"

    function addPass(){
        // console.log("addPass")
        var url = ""
        var platform = "web"
        if(isAndroid){
            url = "https://walletpass.io?u="
            platform = "android"
        }
        if(isIOS){
            platform = "ios"
        }

        //url += `${env && env.apiBase}/api/pass?platform=${platform}&token=${ticket.token}`
        url += `${env && env.apiBase}/api/pass/${platform}/${ticket.token}`

        if(isIOS){
            window.location.href = url
        }
        else if(isAndroid){
            mobile.openLink(url);
        }
    }

    //ticket.eagleStatus = "Eagle"

    return(
        <div className={styles["qrcode-container"]}>
            <div className={styles["header"]}>
                <div className={styles["icon"]}>
                    {ticket.type == "SL" && <IconSpringLeadership size={64}/>}
                    {ticket.type == "FR" && <IconFamilyReunion size={64}/>}
                    {ticket.type == "FED" && <IconFreeEnterprise size={64}/>}
                    {ticket.type == "A+" && <IconAchievers size={64}/>}
                    {ticket.type == "LS" && <IconLeadershipSummit size={64}/>}
                </div>  
                {ticket.type == "FR" && 
                    <div className={styles["header-text"]}>
                        <div className={styles["companyName"]}>{ticket.companyName}</div>
                        <div className={styles["location"]}>{ticket.location}</div>
                        <div className={styles["startDate"]}>{moment.utc(ticket.startDate).local().format("MMMM D, yyyy")}</div>
                    </div>
                }
                {ticket.type !== "FR" && 
                    <div className={styles["header-text"]}>
                        <div className={styles["companyName"]}>{ticket.companyName}</div>
                        <div className={styles["location"]}>{ticket.location} - {moment.utc(ticket.startDate).local().format("MMMM D, yyyy")}</div>
                    </div>
                }
            </div>
            <div className={styles["vertical-spacer"]}>&nbsp;</div>
            <div className={styles["qrcode"]}>
                <img ref={qrCode} alt="QR Code"/>
                {(ticket.eagleStatus || "").toLowerCase() == "double eagle" && 
                <IconDoubleEagle className={styles["eagle-status"]}/>
                }
                {(ticket.eagleStatus || "").toLowerCase() == "eagle" && 
                <IconEagle className={styles["eagle-status"]}/>
                }
            </div>
            <div className={styles["vertical-spacer"]}>&nbsp;</div>
            <div className={styles["footer"]}>
                {isApp && <div className={styles["passes"]}>
                    <a onClick={()=> { addPass() }}>
                    {isIOS && <img src="/apple-wallet.png" alt="Add to Apple Wallet" />}
                    {isAndroid && <img src="/android-wallet.png" alt="Add to WalletPasses" />}
                    </a>
                </div>}
            </div>
            
        </div>
    )
}