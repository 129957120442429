import { useQuery, useQueryClient, useMutation } from "react-query";
import { makeAuthedRequest } from "utils/makeAuthedRequest";
import { createContainer, useContainer } from "unstated-next";
import { useEffect, useMemo, useState } from "react";

/**
 *  QUERY events: useProfileData() -> [{...event}, ...]
 *  UPDATE events: useProfileData.updateEventsMutation([{...event},...])
 */

export const profileEndPoints = {
  // TODO: Check these before deployment
  get: {
    loadProfile: "/api/profile/profile",
  },
};

export function useProfileData(id) {
  // let { tickets } = useContainer(TicketContext);
  const queryClient = useQueryClient();
  const [contactId, setContactId] = useState(id)

  const { isLoading, isSuccess, isError, data, error, fetch, refetch } =
    useQuery(["profile"], () =>
      makeAuthedRequest(
        `${profileEndPoints.get.loadProfile}`,
        { method: "get", headers: { "wwg-contact-id": contactId || 0} }
      ).then(async (res) => {
        const json = await res.json();

        if (res.ok) {
          setContactId(json.contactId);
          return json;
        } else {
          throw new Error(json.message);
        }
      })
    );

  const saveAvatar = useMutation(
    async(avatar) => {
      return makeAuthedRequest(
        `/api/profile/avatar`,
        { 
          method: "PUT",
          body: JSON.stringify(avatar)
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["profile"])
      },
    }
  )

  const profile = useMemo(
    () => (data ? data : []),
    [data]
  );

  return {
    isLoading,
    isSuccess,
    isError,
    data,
    error,
    fetch,
    refetch,
    profile : data || [],
    saveAvatar,
    setContactId,
    contactId
  };
}

export const Profile = createContainer(useProfileData)