import PropTypes from "prop-types";
import styles from "./DateHeader.module.css";
import classNames from "classnames";

/**
 * Displays the date of the upcoming event in a teal [#91B6BB] bar
 */
export default function DateHeader({ date }) {
  return <div className={classNames(styles.container)}>{date}</div>;
}

DateHeader.propTypes = {
  /**
   * Date to display
   */
  date: PropTypes.string,
};
